import styled, { useTheme } from 'styled-components';

interface RadioButtonProps {
  value: string;
  setValue: (value: string) => void;
  maxLength?: number;
  textareaHeight?: string;
  isValidValue?: boolean;
  errorMessage?: string;
}

const Textarea = ({ value, setValue, textareaHeight, maxLength, isValidValue, errorMessage }: RadioButtonProps) => {
  const theme = useTheme();

  const textareaColor = {
    bgColor: theme.layer[1],
    borderColor: theme.border.base,
    textColor: theme.font.base
  };

  if (errorMessage) {
    textareaColor.bgColor = '#FFEAEC';
    textareaColor.borderColor = theme.context.error;
    textareaColor.textColor = '#2E2E2E';
  }

  if (isValidValue) {
    textareaColor.bgColor = '#EFFFEA';
    textareaColor.borderColor = theme.context.success;
    textareaColor.textColor = '#2E2E2E';
  }

  return (
    <>
      <StyledTextarea
        textareaColor={textareaColor}
        height={textareaHeight}
        maxLength={maxLength}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
      {maxLength && (
        <CharactersRemainder isError={Boolean(errorMessage)}>
          <AmountCharacters>{maxLength - value.length > 0 ? maxLength - value.length : 0}</AmountCharacters> Characters Remaining
        </CharactersRemainder>
      )}
      {errorMessage && <Error>{errorMessage}</Error>}
    </>
  );
};

export const StyledTextarea = styled.textarea<{
  textareaColor: {
    bgColor: string;
    borderColor: string;
    textColor: string;
  };
  height?: string;
}>`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ textareaColor }) => textareaColor.textColor};
  width: 100%;
  height: ${({ height }) => (height ? height : '120px')};
  border: 1px solid ${({ textareaColor }) => textareaColor.borderColor};
  border-radius: 4px 4px 0px 0px;
  resize: none;
  outline: 0;
  background-color: ${({ textareaColor }) => textareaColor.bgColor};
  padding: 10px;
`;

const CharactersRemainder = styled.div<{ isError: boolean }>`
  background: ${({ isError, theme }) => (isError ? '#FFEAEC' : theme.layer[2])};
  padding: 6px 10px;
  margin-top: -8px;
  border: ${({ isError, theme }) => (isError ? `1px solid ${theme.context.error}` : `1px solid ${theme.border.base}`)};
  border-radius: 0px 0px 4px 4px;
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.font.weak};
`;

const AmountCharacters = styled.span`
  font-weight: 700;
`;

const Error = styled.p`
  color: #d63b4b;
  font-size: 16px;
  font-weight: 400;
`;

export default Textarea;
