import { INotesData } from '@/components/client-details/types';
import { BiteSizeType, SuitabilityCriteria, SuitabilityEnum } from '@/components/client-entity-details/types';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { IFilterBarData } from '../../../basicComponents/filterBar';
import { IAssetClass, ISubAssetClass } from '../../fat-addInvestors/types';

export enum TabsEnum {
  SUMMARY = 'Summary',
  COMMITMENTS = 'Commitments',
  ALLOCATIONS = 'Begin Allocation',
  ACTIVITY = 'Activity',
  PACING = 'Pacing'
}

export type TypeWindow = 'default' | 'editEntity' | 'opportunityDetails';

export interface AssetClassTargetAllocationDto {
  id: string;
  name: string;
  percentage: number;
  targetAllocation: number;
  economicExposure: number;
  capacity: number;
  currentNAV: number;
  unfundedCommitments: number;
  pacingTarget: IAllocationPacingTarget;
  illiquid: boolean;
  subAssetClasses: SubAssetClassTargetAllocationDto[];
}

export interface SubAssetClassTargetAllocationDto {
  id: string;
  name: string;
  percentage: number;
  targetAllocation: number;
  economicExposure: number;
  capacity: number;
  pacingTarget: IAllocationPacingTarget;
  currentNAV: number;
  unfundedCommitments: number;
  illiquid: boolean;
}

export interface IAllocationPacingTarget {
  startDate: Date;
  cumulativePacingTarget: number;
  cumulativeCommitments: number;
  remainingPacingTarget: number;
  pacingTargetThroughDate: Date;
}

export type ClientEntityDetailsAllocations = {
  ClientEntityDetailsAllocations: ClientEntityAllocations;
};

export type ClientEntityAllocations = {
  id: string;
  name: string;
  totalEntitiesValue: number;
  privatePercentage: number;
  targetOCRatio: number;
  currentOCRatio: number;
  notes: INotesData[];
  biteSize: BiteSizeType;
  ubtiBlocker: boolean;
  custodied: boolean;
  alwaysShow: boolean;
  onHold: boolean;
  impact: string;
  sectorsToAvoid: string[];
  privateRealAssetPercentage: number;
  privateEquityPercentage: number;
  privateCreditPercentage: number;
  diversifyingStrategiesPercentage: number;
  privateEquityCapacity: number;
  privateCreditCapacity: number;
  privateRealAssetsCapacity: number;
  diversifyingStrategiesCapacity: number;
  progressBarValues: undefined;
  allocations: ClientEntityAllocationsTableType[];
};

export type ClientEntityAllocationsTableType = {
  investmentId: string;
  name: string;
  suitability: SuitabilityEnum;
  status: string;
  investorDocsDue: string;
  assetClass: IAssetClass;
  subAssetClass: ISubAssetClass;
  minimum: number;
  suitabilityCriteria: SuitabilityCriteria[];
  proposalType: string;
  committedCapital: number;
};

export type ClientEntityDetailsCommitments = {
  ClientEntityDetailsCommitments: ClientEntityCommitments;
};

export type ClientEntityCommitments = {
  name: string;
  totalEntitiesValue: number;
  privatePercentage: number;
  targetOCRatio: number;
  currentOCRatio: number;
  notes: INotesData[];
  alwaysShow: boolean;
  onHold: boolean;
  impact: string;
  sectorsToAvoid: string[];
  privateRealAssetPercentage: number;
  privateEquityPercentage: number;
  privateCreditPercentage: number;
  diversifyingStrategiesPercentage: number;
  privateEquityCapacity: number;
  privateCreditCapacity: number;
  privateRealAssetsCapacity: number;
  diversifyingStrategiesCapacity: number;
  custodied: boolean;
  ubtiBlocker: boolean;
  biteSize: BiteSizeType;
  progressBarValues: undefined;
  commitments: CommitmentsType[];
};

export type CommitmentsType = {
  id: string;
  name: string;
  committedCapital: number;
  capitalCalled: number;
  capitalRemaining: number;
  distributionsToDate: string;
  marketValue: number;
  status: string;
  assetClass: IAssetClass | null;
  subAssetClass: ISubAssetClass | null;
  moreInfo: CommitmentsMoreInfoType;
  investmentId: string;
  valuationAmount: number | null;
  valuationDate: Date | null;
  aggregateCallsAndDistributions: number | null;
};

export type CommitmentsMoreInfoType = {
  id: string;
  managerId: string;
  assetClass: string;
  nextClose: string;
  finalClose: string;
  investorDocsDue: string;
  minimum: number;
  custodied: boolean;
  ubtiBlocker: boolean;
  sri: boolean;
  targetIRR: number;
  targetYield: number;
};

export interface IListEntityTransactions {
  total: number;
  page: number;
  perPage: number;
  data: IListEntityTransactionsData[];
  investments: Investment[];
  filters: IFilterBarData[];
}

export interface IListEntityTransactionsData {
  date: Date;
  strategy: string;
  transactionType: string;
  amount: number;
  totalAmount: number;
  distributionsToDate: number;
  remainingCapital: number;
  marketValue: number;
}
